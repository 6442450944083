<template>
  <div>
    <a-select
      :default-value="status"
      class="col-3 mr-3"
      @change="handleChange"
    >
      <a-select-option value="PENDING">
        PENDING
      </a-select-option>
      <a-select-option value="APPROVED">
        APPROVED
      </a-select-option>
      <a-select-option value="REJECTED">
        REJECTED
      </a-select-option>
    </a-select>

    <div class="table-responsive">
      <table class="table table-hover text-nowrap">
        <thead>
          <tr>
            <th>Account</th>
            <th>Provider company</th>
            <th>Contact Person</th>
            <th>Phone Number</th>
            <th>Email</th>
            <th>Submitted On</th>
            <th
              colspan="2"
              class="text-center"
            >
              Approved Range
            </th>
            <th>Status</th>
          </tr>
          <tr>
            <th />
            <th />
            <th />
            <th />
            <th />
            <th />
            <th class="text-center">
              From
            </th>
            <th class="text-center">
              To
            </th>
            <th class="text-center" />
          </tr>
        </thead>
        <tbody v-if="items">
          <tr
            v-for="item in items"
            :key="item.id"
          >
            <td>{{ item.user ? item.user.registration.company_name : '' }}</td>
            <td>{{ item.carrier_name }}</td>
            <td>{{ item.contact_name }}</td>
            <td>{{ item.phone_number }}</td>
            <td>{{ item.email }}</td>
            <td>{{ getDate(item.created_on) }}</td>
            <td class="text-center">
              {{ item.tn_range_begin }}
            </td>
            <td class="text-center">
              {{ item.tn_range_end }}
            </td>
            <td v-if="item.status === 'PENDING'">
              <div class="d-flex">
                <a class="a_action_icon bg-success mb-2 mr-2">
                  <i
                    class="fa fa-check"
                    aria-hidden="true"
                    @click="approve(item)"
                  />
                </a>

                <a class="a_action_icon bg-danger mb-2 mr-2">
                  <i
                    class="fa fa-close"
                    aria-hidden="true"
                    @click="reject(item)"
                  />
                </a>
              </div>
            </td>

            <td v-if="item.status === 'APPROVED'">
              <a class="a_action_icon bg-danger mb-2 mr-2">
                <i
                  class="fa fa-close"
                  aria-hidden="true"
                  @click="reject(item)"
                />
              </a>
            </td>

            <td v-if="item.status === 'REJECTED'">
              <a class="a_action_icon bg-success mb-2 mr-2">
                <i
                  class="fa fa-check"
                  aria-hidden="true"
                  @click="approve(item)"
                />
              </a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <a-pagination
      v-if="total"
      class="pagination-right mt-2"
      :default-current="1"
      :total="total"
      @change="pagination($event)"
    />
  </div>
</template>

<script>
import moment from '@/plugins/moment'
import { getLoaActionType } from "../../store/adminLoa";
import {notification} from "ant-design-vue";
import axios from '@/axios'
export default {
  name: "Loa",
  data() {
    return {
      step: 10,
      skip: 0,
      total: null,
      errors: null,
      status: 'PENDING',
      reason: 'Failed',
      items: [],
    }
  },
  computed: {},
  created() {
    this.loadItems()
  },
  methods: {
    getDate(date) {
      return moment(date)
    },
    approve (item){
      axios
          .post(`/admin/signing/loa/${item.id}/approve`)
          .then(() => {
            notification.success({
              message: 'Successfully Approve',
            })
            this.loadItems()
          }).catch(() => {})
    },
    reject(item) {
      const reason = {
        reason: this.reason
      }
      axios
          .post(`/admin/signing/loa/${item.id}/reject`, reason)
          .then(() => {
            notification.success({
              message: 'Rejected',
            })
            this.loadItems()
          }).catch(() => {})
    },
    pagination(int) {
      this.skip = 0
      this.skip += this.step * int - this.step
      this.loadItems()
    },
    handleChange(val) {
      this.status = val
      this.filteredByStatus()
    },
    loadItems() {
      const loa = {
        skip: this.skip,
        status: this.status,
      }
      this.$store.dispatch(getLoaActionType.getLoaAction, loa)
      .then(res => {
          this.items = res.data.data
          this.total = res.data.count > this.step ? res.data.count : res.data.count
      }).catch(() =>{})
    },
    filteredByStatus() {
      const status = {
        status: this.status,
      }
      this.$store.dispatch(getLoaActionType.filteredByStatusAction, status)
      .then(res => {
        this.items = res.data.data
        this.total = res.data.count > this.step ? res.data.count : null
      }).catch(() =>{})
    }
  }
}
</script>

<style scoped>
.pagination-right {
  text-align: right!important;
  margin-right: 86px!important;
}
</style>